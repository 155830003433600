import Space from '@rebass/space'
import { graphql, Link } from 'gatsby'
import Container from '../components/Container'
import { Flex, Box } from 'reflexbox'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import Card from '../components/Card'
import SEO from '../components/SEO'
import GradientWrapper from '../components/GradientWrapper'
import { ThemeConsumer } from 'styled-components'
import LogoUI8 from '../images/vector/logo-ui8.svg'
import LogoSmashicons from '../images/vector/smashicons.svg'
import LogoGumroad from '../images/vector/gumroad.svg'
import LogoCraftwork from '../images/vector/craftwork.svg'

let thumbnailsArray = []

const StyledButtonGroup = styled(Box)`
  text-align: center;

  button {
    display: block; // Weird ass Safari issue
    margin: 0; // Weird ass Safari issue
  }

  *:first-child button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.inactive {
      border-right: 0;
    }
  }

  *:last-child button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &.inactive {
      border-left: 0;
    }
  }
`

const StyledMenuBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${props =>
    props.theme.appearance === 'light' ? '#fafafa' : 'rgba(0,0,0, .15)'};
  height: 24px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0));
`

const FeaturedIcons = ({ props, data }) => {
  data.thumbnail.edges.map((edge, index) => {
    thumbnailsArray[edge.node.relativeDirectory] = [edge.node]
  })

  function defaultCategory() {
    if (typeof window !== 'undefined' && localStorage.getItem('category')) {
      return localStorage.getItem('category')
    } else {
      return 'premium'
    }
  }

  const [category, setCategory] = useState(defaultCategory())

  function updateCategory(value) {
    setCategory(value)
    if (typeof window !== 'undefined') {
      localStorage.setItem('category', value)
    }
  }

  return (
    <ThemeConsumer>
      {theme => (
        <>
          <SEO title="Resources • Our favourite free and paid icon sets from across the web" />

          {/* Hero */}
          <Container my={[4, 5]}>
            <Box textAlign={'center'}>
              <h1>Resources</h1>
            </Box>
          </Container>

          <Container my={[4, 5]}>
            <Flex flexWrap="wrap" alignItems="center" mb={4}>
              <Box width={[1, 1 / 2, 1 / 5]} mb={[3, 3, 0]}>
                <a href="https://www.ui8.net/?rel=icnjr">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#2B292B'
                    }}
                    mx={[0, 3, 3]}
                    animateonhover="true">
                    <LogoUI8 width="48" height="48" />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 5]} mb={[3, 3, 0]}>
                <a href="https://smashicons.com">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#2a2d2d'
                    }}
                    mx={[0, 3, 3]}
                    animateonhover="true">
                    <LogoSmashicons width="64" height="64" />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 5]} mb={[3, 3, 0]}>
                <a href="https://iconfinder.com">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#14984D'
                    }}
                    mx={[0, 3, 3]}
                    animateonhover="true">
                    <Img
                      fixed={data.logoIconfinder.childImageSharp.fixed}
                      alt="Iconfinder logos"
                    />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 5]} mb={[3, 3, 0]}>
                <a href="https://discover.gumroad.com/design/icons?a=581296979">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#FFC900'
                    }}
                    mx={[0, 3, 3]}
                    animateonhover="true">
                    <LogoGumroad width="128" />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 5]}>
                <a href="https://craftwork.design/?ref=333">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#ececec'
                    }}
                    mx={[0, 3, 3]}
                    animateonhover="true">
                    <LogoCraftwork width="128" />
                  </Card>
                </a>
              </Box>
            </Flex>
            <Flex
              mx={[0, 3]}
              pt={2}
              mb={4}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap="wrap">
              <Box width={1}>
                <h2>Our favourite icon sets</h2>
              </Box>
              <StyledButtonGroup>
                <Button
                  onClick={() => updateCategory('premium')}
                  role="button"
                  colorscheme={category === 'premium' ? 'blue' : 'white'}
                  className={category === 'premium' ? 'active' : 'inactive'}
                  preventAnimation>
                  Premium
                </Button>
                <Button
                  onClick={() => updateCategory('free')}
                  role="button"
                  colorscheme={category === 'free' ? 'blue' : 'white'}
                  className={category === 'free' ? 'active' : 'inactive'}
                  preventAnimation>
                  Free
                </Button>
              </StyledButtonGroup>
            </Flex>
            <Flex flexWrap="wrap" mb={4}>
              {data.jsonData.edges.map(
                (iconSet, index) =>
                  category === iconSet.node.category && (
                    <Box
                      width={[1, 1 / 2, 1 / 3]}
                      px={[0, 3, 3]}
                      mb={[3, 4]}
                      key={index}>
                      <Box as={Link} to={'/resources/' + iconSet.node.slug}>
                        <Card subtle animateonhover="true">
                          <Img
                            fluid={
                              thumbnailsArray[iconSet.node.slug][0]
                                .childImageSharp.fluid
                            }
                            alt={'Thumbnail for ' + iconSet.node.name}
                          />
                          <Space p={3}>
                            <div>
                              <h4>{iconSet.node.name}</h4>
                              <p style={{ marginBottom: '0' }}>
                                {iconSet.node.numberOfIcons} icons by{' '}
                                {iconSet.node.author.name}
                              </p>
                            </div>
                          </Space>
                        </Card>
                      </Box>
                    </Box>
                  )
              )}
            </Flex>
          </Container>
        </>
      )}
    </ThemeConsumer>
  )
}

export default FeaturedIcons

export const query = graphql`
  query {
    jsonData: allFeaturedIconsJson(sort: { order: DESC, fields: setId }) {
      edges {
        node {
          name
          slug
          category
          numberOfIcons
          author {
            name
          }
        }
      }
    }
    thumbnail: allFile(
      filter: { name: { eq: "thumbnail@2x" } }
      sort: { fields: relativeDirectory }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 355, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          relativeDirectory
        }
      }
    }
    sketchPluginIcon: file(relativePath: { eq: "sketchplugin@2x.png" }) {
      childImageSharp {
        fixed(width: 64, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    sketchPluginMenu: file(relativePath: { eq: "sketchplugin-menu@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 248, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchPluginMenuDark: file(
      relativePath: { eq: "sketchplugin-menu-dark@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 248, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logoIconfinder: file(relativePath: { eq: "logo-iconfinder@2x.png" }) {
      childImageSharp {
        fixed(width: 151, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
